<template>
  <div style="width: 100% ;padding: 10px">
    <el-row>
      <el-col style="padding-top: 5px;padding-left: 5px" :span=6>
<!--        <el-button @click="propertyCheck()">属性批量更新</el-button>-->
<!--        <el-upload-->
<!--            class="avatar-uploader"-->
<!--            action=""-->
<!--            :show-file-list="true"-->
<!--            :on-success="downLoadErrorList"-->
<!--            :on-change="propertyUpdateExcel">-->
<!--          <el-button size="small" type="primary">上传excel更新配置信息</el-button>-->
<!--        </el-upload>-->
        <el-card v-loading="treeLoading">
          <el-row>
            <el-col :span="18">
              <el-input
                  placeholder="输入关键字进行过滤"
                  v-model="filterText">
              </el-input>
            </el-col>
            <el-col :span="6">
              <el-button @click="keySelect">筛选</el-button>
            </el-col>
          </el-row>
          <el-tree
              ref="propertyTree"
              :data="propertyTree"
              :props="defaultProps"
              node-key="id"
              :filter-node-method="filterNode"
              :expand-on-click-node="false">
          <span class="custom-tree-node" style="padding: 10px" slot-scope="{ data }">
<!--            <i class="el-icon-caret-right"/>-->
            <span>
              <el-button
                  type="text"
                  v-if="data.level !== 0"
                  @click="show(data)">{{ data.name }}
              </el-button>
              <span v-if="data.level === 0">{{ data.name }}</span>
            </span>
            <span>
              <el-button
                  style="padding-left: 20px"
                  type="text"
                  size="mini"
                  icon="el-icon-circle-plus-outline"
                  @click="() => append(data)">
              </el-button>
              <el-button
                  type="text"
                  size="mini"
                  v-if="data.isEntity !== 0 || (data.treeChildren !== undefined && data.treeChildren.length === 0)"
                  style="padding-left: 3px"
                  icon="el-icon-remove"
                  @click="() => {removeData = data,removePasswordCheck = true}">
              </el-button>
            </span>
          </span>
          </el-tree>
        </el-card>
      </el-col>
      <el-col style="padding-top: 5px;padding-left: 5px" :span=16>
        <el-row>
          <h2>{{title}}</h2>
          <el-card>
            <el-switch v-if="ifShow && form.id" v-model="update" active-text="编辑"/>
            <el-form v-if="ifShow" :ref="form.id">
              <el-form-item v-show="false" label="id">
                <el-input v-model="form.id" :disabled="!(update || form.id === null)"/>
              </el-form-item>
              <el-form-item label="属性名称">
                <el-input v-model="form.name" :disabled="!(update || form.id === null)"/>
              </el-form-item>
              <el-form-item v-show="false" label="level">
                <el-input v-model="form.level" :disabled="!(update || form.id === null)"/>
              </el-form-item>
              <el-form-item v-show="false" label="父级id">
                <el-input v-model="form.parentId" disabled></el-input>
              </el-form-item>
              <el-form-item label="属性值类型">
                <el-select v-model="form.valueType" placeholder="请选择" @change="uploadImage = false , form.value = (form.valueType === 2?0:'')" :disabled="!(update || form.id === null)">
                  <el-option
                      v-for="item in options"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="form.valueType === 4" label="选项卡内容">
                <el-input v-model="form.children" :disabled="!(update || form.id === null)"/>
              </el-form-item>
              <el-form-item v-if="form.valueType === 5" label="关联到实体">
                <span>{{formValueName}}</span>
                <el-button @click="entitySelectShow = true" :disabled="!(update || form.id === null)">关联到其他项</el-button>
              </el-form-item>
              <el-form-item v-if="form.valueType === 5" label="关联筛选">
                <el-select
                    :disabled="!(update || form.id === null)"
                    v-model="form.children"
                    multiple
                    placeholder="请选择">
                  <el-option
                      v-for="item in entityChildren"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="form.valueType !== 5 && form.valueType !== 2" label="默认值">
                <el-input v-if="form.valueType !== 3 && form.valueType !== 6 && form.valueType !== 7" v-model="form.value" :disabled="!(update || form.id === null)"/>
                <el-upload
                    v-if="form.valueType === 6"
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :disabled="!(update || form.id === null)"
                    :on-success="handleAvatarSuccess"
                    :on-change="UploadImage">
                  <img v-if="uploadImage" :src="(baseURL+'/getImage/'+form.value)" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <el-upload
                    v-if="form.valueType === 7"
                    class="avatar-uploader"
                    action=""
                    :show-file-list="true"
                    :disabled="!(update || form.id === null)"
                    :on-success="handleAvatarSuccess"
                    :on-change="UploadFile">
                  <el-button v-if="!uploadImage && (update || form.id === null)" size="small" type="primary">点击上传</el-button>
                  <el-button v-if="uploadImage && (update || form.id === null)" size="small" type="primary" @click="form.value = '',uploadImage=false">重新上传</el-button>
                </el-upload>
                <el-button v-if="form.valueType === 7 && uploadImage && !(form.id === null)" size="small" type="primary" @click="getFile(form.value)">下载文件</el-button>
<!--                <el-input v-if="form.valueType !== 7" v-model="form.value" :disabled="!(update || form.id === null)"/>-->
                <el-date-picker
                    v-if="form.valueType === 3"
                    v-model="form.value"
                    :disabled="!(update || form.id === null)"
                    type="datetime"
                    placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>
              <el-form-item v-show="false" label="可删除">
                <!--              <el-switch v-model="form.deletable"></el-switch>-->
                <el-radio v-model="form.deletable" :label="0" :disabled="!(update || form.id === null)">可删除</el-radio>
                <el-radio v-model="form.deletable" :label="1" :disabled="!(update || form.id === null)">禁止删除</el-radio>
              </el-form-item>
              <el-form-item label="为必填项">
                <el-radio v-model="form.mast" :label="0" :disabled="!(update || form.id === null)">非必填</el-radio>
                <el-radio v-model="form.mast" :label="1" :disabled="!(update || form.id === null)">必填</el-radio>
              </el-form-item>
              <el-form-item label="禁止编辑">
                <el-radio v-model="form.disable" :label="0" :disabled="!(update || form.id === null)">可以编辑</el-radio>
                <el-radio v-model="form.disable" :label="1" :disabled="!(update || form.id === null)">禁止编辑</el-radio>
              </el-form-item>
              <el-form-item label="可创建实体">
                <el-radio v-model="form.isEntity" :label="0" :disabled="!(update || form.id === null)">非可创建实体</el-radio>
                <el-radio v-model="form.isEntity" :label="1" :disabled="!(update || form.id === null)">可创建实体</el-radio>
              </el-form-item>
              <el-form-item label="出纳属性">
                <el-radio v-model="form.cashier" :label="0" :disabled="!(update || form.id === null)">非出纳属性</el-radio>
                <el-radio v-model="form.cashier" :label="1" :disabled="!(update || form.id === null)">出纳属性</el-radio>
              </el-form-item>
              <el-form-item label="绑定方法">
                <el-select
                    v-model="form.spare1"
                    placeholder="请选择"
                    clearable
                    :disabled="!(update || form.id === null)">
                  <el-option
                      v-for="item in functionList"
                      v-if="item.codition === 0 || form.valueType === 5 || (item.codition === form.valueType)"
                      :key="item.label"
                      :label="item.label"
                      :value="item.label">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="方法对应子属性名称" v-show="form.spare1 !== '' && form.spare1 !== null && form.spare1 !== undefined">
                <el-input v-model="form.spare2" :disabled="!(update || form.id === null)"/>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="save()" v-show="update || form.id === null" :disabled="form.level === 0">保存</el-button>
              </el-form-item>
            </el-form>
          </el-card>
          <el-card v-if="ifShow && form.id">
            <div class="block" >
              <el-select v-model="selectType" filterable :placeholder="selectTypeOption[0]">
                <el-option
                    v-for="item in selectTypeOption"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
              <el-select v-model="groupType" filterable :placeholder="groupTypeOption[0].value">
                <el-option
                    v-for="item in groupTypeOption"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="time" filterable :placeholder="timeOption[0].value">
                <el-option
                    v-for="item in timeOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="timeType" filterable :placeholder="timeTypeOption[0].value">
                <el-option
                    v-for="item in timeTypeOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-button @click="selectEntityGroupBy()">查询</el-button>
            </div>
<!--            <div>-->
<!--              <span v-if="JSON.stringify(groupDate)=='{}'">-->
<!--                暂无数据-->
<!--              </span>-->
<!--              <span v-if="JSON.stringify(groupDate)!='{}'" v-for="(value,key) in groupDate" :key='key'>-->
<!--                <el-row>-->
<!--                  <span v-if="selectType === '统计子属性'">-->
<!--                  {{key+":    "}}-->
<!--                  <span v-for="(value1,key1) in value" :key='key1'>-->
<!--                    <span v-if="value1 !==0 ">{{key1+":    "+value1}}</span>-->
<!--                  </span>-->
<!--                </span>-->
<!--                <span v-if="selectType === '查询本属性'">-->
<!--                      <span v-if="value !==0 ">{{key+":    "+value}}</span>-->
<!--                </span>-->
<!--                </el-row>-->
<!--              </span>-->
<!--            </div>-->
          </el-card>
        </el-row>
      </el-col>
    </el-row>
    <!--  弹窗  -->
<!--    <el-dialog :visible.sync="dialogVisible" :title="name" width="20%">-->
<!--      <img :src="(url)" alt="" style="width: 100%">-->
<!--      <el-button @click="img_download()">下载</el-button>-->
<!--    </el-dialog>-->
    <el-dialog
        :visible.sync="entitySelectShow"
        width="50%">
      <el-tree
          :data="propertyTree"
          :props="defaultProps"
          node-key="id"
          :expand-on-click-node="false">
            <span class="custom-tree-node" style="padding: 10px" slot-scope="{ data }">
  <!--            <i class="el-icon-caret-right"/>-->
              <span>
                <el-button
                    type="text"
                    @click="form.value = data.id,getTreeNameById(data.id),entitySelectShow = false">{{ data.name }}
                </el-button>
                <!--              <span v-if="data.level === 0">{{ data.name }}</span>-->
              </span>
            </span>
      </el-tree>
    </el-dialog>
    <el-dialog
        :visible.sync="removePasswordCheck"
        :before-close="handleClose"
        width="50%">
      <el-input placeholder="请输入密码" v-model="inputPassWord" show-password></el-input>
      <el-button @click="remove(removeData)">确定</el-button>
    </el-dialog>
  </div>
</template>

<script>

import request from "@/utils/request";
import upload from "element-ui/packages/upload";
import {serverIp} from "../../public/config";
import {exportExcelFromFront} from "@/utils/xlsxExport";
import {getMainMemory} from "@/utils/product";

export default {
  computed: {
    upload() {
      return upload
    }
  },
  data() {
    return {
      removePasswordCheck: false,
      removeData: {},
      inputPassWord: "",
      update: false,
      title: "",
      form: {},
      ifShow: false,
      propertyTree: [],
      options:[
          {
        id:1,
        value:"文字"
      },{
        id:2,
        value:"数值"
      },{
        id:3,
        value:"日期"
      },{
        id:4,
        value:"选项卡"
      },{
        id:5,
        value:"列表"
      },{
        id:6,
        value:"图片"
      },{
        id:7,
        value:"文件"
      }],
      defaultProps: {
        children: 'treeChildren',
        label: 'name'
      },
      entitySelectShow:false,
      formValueName: "",
      selectTypeOption:["查询本属性","统计子属性"],
      selectType: "查询本属性",
      groupTypeOption:[{
        value:"SUM",
        name:"求和"
      }],
      groupType:"SUM",
      timeOption:[
          {
        value: '本年',
        label: '本年'
      }, {
        value: '去年',
        label: '去年'
      }, {
        value: '本季度',
        label: '本季度'
      }, {
        value: '上季度',
        label: '上季度'
      }, {
        value: '本月',
        label: '本月'
      }, {
        value: '上个月',
        label: '上个月'
      }, {
        value: '6个月内',
        label: '6个月内'
      }, {
        value: '本周',
        label: '本周'
      }, {
        value: '上周',
        label: '上周'
      }],
      time:"本年",
      timeTypeOption:[
        {
          value: 'createTime',
          label: '创建时间'
        },{
          value: 'updateTime',
          label: '修改时间'
        },{
          value: 'intime',
          label: '工件创建时间'
        },{
          value: 'outtime',
          label: '工件入库时间'
        }
      ],
      timeType:"createTime",
      groupDate: {},
      entityChildren: [],
      functionList:[
        {
          label:"出纳:取用",
          codition:2
        },
        {
          label:"出纳:取用一件",
          codition:0
        },
      ],
      functionValueName:"",
      uploadImage : false,
      fileList:[],

      treeLoading : false,
      baseURL:"",
      filterText: "",
    }
  },
  //加载表格
  mounted() {
    this.selectTree()
    this.baseURL = serverIp.startsWith("localhost") ? `http://${serverIp}` : `https://${serverIp}`
  },
  methods: {
    //查询cmdb树
    selectTree() {
      this.treeLoading = true;
      this.title = ""
      this.form = {}
      this.ifShow=false
      this.update=false
      getMainMemory("getPropertyTreeMemory").then((res) => {
        this.propertyTree = res
        console.log("propertyTree",res)
        this.treeLoading = false;
      // request.post("/CMDB/getPropertyTree").then(res => {
      //   this.propertyTree = res.data
      // }).then(() => {
      //   this.treeLoading = false;
      }), (error) => {
        this.$message({
          type: 'warning',
          message: '获取cmdb树失败'
        });
      }
      this.treeLoading = false;
    },
    append(data) {
      this.title = data.name + "-新建子属性"
      this.ifShow = false;
      this.ifShow = true;
      this.form = {
        id:null,
        level:data.level+1,
        parentId:data.id,
        valueType:1,
        deletable:0,
        mast:0,
        disable:0,
        isEntity:0,
        cashier:0
      }
    },
    remove(data) {
      if (this.inputPassWord !== "tiansheng8888"){
        this.$message({
          type: 'warning',
          message: '密码错误'
        });
        return
      }
      this.treeLoading = true
      this.inputPassWord = ""
      request.post("/CMDB/deletePropertyById",data.id).then(res => {
        this.selectTree();
        this.removePasswordCheck = false
      }), (error) => {
        this.$message({
          type: 'warning',
          message: '删除失败'
        });
      }
    },
    propertyCheck() {
      this.treeLoading = true
      request.post("/CMDB/propertyCheckModelsAll").then(res => {
        // this.selectTree();
      }), (error) => {

      }
    },
    //上传图片的方法
    propertyUpdateExcel(file,filelist) {
      let fd = new FormData()
      fd.append('file', file.raw) //传给后台接收的名字 file
      request.post("/CMDB/propertyUpdateExcel", fd, {headers: {'Content-Type': 'multipart/form-data'}}).then(res => {
        //上传成功后返回的数据,
        // 将图片地址给到表单.
        if (res.code === "200"){
          let cellList = res.data;
          let caption='导出失败记录'
          let exportName='导出失败记录'
          let headerList = ["类型","型号","图纸号","数量"]
          this.$nextTick(()=>{
            let params = {
              caption,
              headerList,
              cellList,
              exportName,
            };
            exportExcelFromFront(params);
          })
        }else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
    },
    save(){
      this.treeLoading = true
      function notEmpty(s){
        return(s !== null && s!==undefined && s !== "")
      }
      let s = ""
      if (this.form){
        if (!notEmpty(this.form.name)){
          s += "属性名称 "
        }
        if (!notEmpty(this.form.valueType)){
          s += "属性值类型 "
        }
        if(this.form.valueType === 4 && !notEmpty(this.form.children)){
          s += "选项卡内容 "
        }
      }
      if (notEmpty(s)){
        this.$message({
          type: 'warning',
          message: '请填写 ' + s
        });
      } else {
        // this.form.deletable = this.form.deletable ? 1 : 0
        // this.form.mast = this.form.mast ? 1 : 0
        // this.form.disable = this.form.disable ? 1 : 0
        // this.form.isEntity = this.form.isEntity ? 1 : 0
        if (this.form.children !== null && this.form.children !== undefined && this.form.children !== ""){
          try{
            this.form.children = this.form.children.join(",")
          } catch (e) {}
        }
        if (this.form.id === null || this.form.id === undefined || this.form.id === 0){
          request.post("/CMDB/saveProperty",this.form).then(res => {
            this.propertyTree = res.data
          }).then(()=>{
            this.selectTree()
          }), (error) => {
            this.$message({
              type: 'warning',
              message: '获取cmdb树失败'
            });
          }
        } else {
          request.post("/CMDB/upProperty",this.form).then(res => {
            this.propertyTree = res.data
          }).then(()=>{
            this.selectTree()
          }), (error) => {
            this.$message({
              type: 'warning',
              message: '获取cmdb树失败'
            });
          }
        }
      }

    },
    getTreeNameById(id){
      let s = ""
      for (const item of this.propertyTree) {
        let _s = this.treeNameSelect(item,id);
        if(_s !== null && _s !== undefined && _s !== ""){
          s=_s
        }
      }
      this.formValueName = s
    },
    selectEntityGroupBy(){
      let selectUrl = ""
      if (this.selectType === "统计子属性"){
        selectUrl = "/entity/getParentEntityGroupBy"
      } else if (this.selectType === "查询本属性"){
        selectUrl = "/entity/getEntityGroupBy"
      }
      let query = {
        cmdbId: this.form.id,
        groupType: this.groupType,
        time:this.time,
        timeType:this.timeType
      }
      request.post(selectUrl,query).then(res => {
        this.groupDate = res.data;
      }), (error) => {

      }
    },
    treeNameSelect(obj,id){
      if (obj.id == id){
        this.entityChildren=obj.treeChildren
        return obj.name
      } else {
        if (obj.treeChildren){
          let name = "";
          for (const item of obj.treeChildren) {
            name = this.treeNameSelect(item,id)
            if(name !== "" && name !== undefined && name !== null){
              return name;
            }
          }
        }
        return "";
      }
    },
    show(data){
      this.title = data.name
      this.form = {}
      this.ifShow = false
      this.ifShow = true
      this.form = data
      this.groupDate = {}
      this.uploadImage = false
      if (this.form.valueType === 5 && this.form.children !== null && this.form.children !== undefined && this.form.children !== "" && (typeof this.form.children) === "string"){
        this.form.children = this.form.children.split(",").map(Number)
      }
      if ((this.form.valueType === 6 || this.form.valueType === 7) && this.form.value !== undefined && this.form.value !== null && this.form.value !== ""){
        this.uploadImage = true;
      }
      if (data.valueType===5){
        this.getTreeNameById(data.value)
      } else {
        this.formValueName=""
        this.entityChildren = []
      }
    },
    //上传图片的方法
    UploadImage(file,filelist) {
      let fd = new FormData()
      fd.append('file', file.raw) //传给后台接收的名字 file
      request.post('/upload/image', fd, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => {
        //上传成功后返回的数据,
        // 将图片地址给到表单.
        this.form.value=response.data
        this.uploadImage = true;
      })

    },
    //过滤
    filterNode(value, data) {
      if (!value || value === "") return true;
      return data.name.indexOf(value) !== -1;
    },
    keySelect(){
      this.$refs.propertyTree.filter(this.filterText);
      if (this.filterText === undefined || this.filterText === null || this.filterText === ""){
        this.treeSelect()
      }
    },
    treeSelect(){
      this.propertyTree.forEach(item => {
        this.propertyTreeSelect(item)
      })
    },
    propertyTreeSelect(item){

      this.$refs.propertyTree.store.nodesMap[item.id].expanded = false;
      if (item.treeChildren !== undefined && item.treeChildren !== null && item.treeChildren.length !== 0){
        item.treeChildren.forEach(children => {
          this.propertyTreeSelect(children)
        })
      }
    },
    //上传图片的方法
    UploadFile(file,filelist) {
      let fd = new FormData()
      fd.append('file', file.raw) //传给后台接收的名字 file
      request.post('/upload/file', fd, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => {
        //上传成功后返回的数据,
        // 将图片地址给到表单.
        this.form.value = response.data
        this.uploadImage = true;
      })

    },
    getFile(str){
      window.open(this.baseURL+'/getFile/'+str, '_blank')
    },
    handleAvatarSuccess(res, file) {
      this.form.value = URL.createObjectURL(file.raw);
    },
    downLoadErrorList(res, file) {

    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      return isJPG;
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            this.inputPassWord = ""
            done();
          })
          .catch(_ => {});
    },
  }
}

</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>